*{
  margin: 0;
  padding : 0; 
  box-sizing: border-box;
  /* font-family: DM Sans */
}

.grad{
  background: url("./assets/header-bg.svg");
  background-size: cover;
  background-repeat: no-repeat ;
}

.butt-grad{
  background: linear-gradient(to right, rgb(57, 101, 224, .4), rgb(130, 215, 255, .4));
}

.rad-grad{
  background: radial-gradient(#252D54, #000);
}

.rad-grad-trans{
  background: radial-gradient(#33aaff2f 0%,#33aaff16 20%,#33aaff13 40%,#33aaff00 60%, #33aaff00 80%);
}